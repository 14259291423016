import * as React from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { format } from 'date-fns'
import Image from 'next/image'
import Link from 'next/link'
import { RecipeBookmarkButton } from 'src/components/Recipes/RecipeBookmarkButton'
import { Badge } from 'src/components/ui/badge'
import { ShadcnButton } from 'src/components/ui/button'
import { Card, CardHeader } from 'src/components/ui/card'
import { Separator } from 'src/components/ui/separator'
import { Heading, Text } from 'src/components/ui/Typography'
import { mainNutrients } from 'src/routes/Recipe/utils/nutrition-utils'

export interface RecipeCardProps {
  slug: string
  href: string
  publishedAt: Date
  imageSrc: string
  MetaDataJson: string
  title: string
  shortDescription: string
  averageRating: number
  numOfRatings: number
  isBookmarked: boolean
}

const RecipeNutrientsOverview = ({ MetaDataJson }: { MetaDataJson: string }) => {
  const nutrients = React.useMemo(() => {
    try {
      return JSON.parse(MetaDataJson) as Array<{
        id: number
        label: string
        amount: number
        unitName: string
      }>
    } catch {}
    return []
  }, [MetaDataJson])

  return (
    <div className="relative">
      {/* <div className="absolute -bottom-1 -top-1 -left-1 -right-1 rounded z-[0] bg-amber-50 opacity-75"/> */}
      <div className="relative z-[1] flex items-center justify-center space-x-2 text-sm color bg-gray-50/0 rounded-md p-1 text-center">
        {nutrients.map((nutrient, index) => (
          <React.Fragment key={nutrient.id}>
            {index > 0 && <Separator orientation="vertical" className="h-4" />}
            <div className="flex flex-col">
              <Text size="xs">{nutrient.label}</Text>
              <Text size="sm" weight="semi">
                {nutrient.amount?.toFixed(0) ?? '-'}
              </Text>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export const RecipeCard = ({
  slug,
  isBookmarked,
  publishedAt,
  numOfRatings,
  averageRating,
  MetaDataJson,
  imageSrc,
  title,
  href,
  shortDescription,
}: RecipeCardProps) => (
  <Card className="border-0 shadow-none flex flex-col hover:shadow-2xl animate-in duration-300 transform hover:-translate-y-1 hover:bg-amber-100/10">
    <CardHeader className="p-2">
      <div className="relative w-full pb-[130%]">
        <Image
          className="rounded-sm overflow-hidden"
          fill
          style={{ objectFit: 'cover' }}
          src={imageSrc}
          alt={title}
        />
        {publishedAt.getTime() > Date.now() && (
          <Badge
            variant="destructive"
            className="rounded-sm px-1 font-normal absolute top-1 right-1"
          >
            Scheduled - {format(publishedAt, 'PP HH:mm')}
          </Badge>
        )}

        <Link href={href} className="absolute top-0 left-0 right-0 bottom-0" />

        <div className="absolute bottom-0 left-0 w-full p-2 flex justify-between">
          <div />
          <RecipeBookmarkButton slug={slug} isBookmarked={isBookmarked} />
        </div>
        <div className="absolute bottom-0 left-0 w-full p-2 flex justify-between" />
      </div>
    </CardHeader>

    <Link href={href} className="hover:underline">
      <Heading size="h4" headingStyle="hand" className="p-2">
        {title}
      </Heading>
    </Link>
    <Text className="p-2 pt-0" size="base">
      {shortDescription.slice(0, 100)}...
    </Text>
    {/* <div className="p-2 mt-auto"> */}
    {/*  <RecipeNutrientsOverview MetaDataJson={MetaDataJson} /> */}
    {/* </div> */}
  </Card>
)
