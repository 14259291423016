import React from 'react'
import parseISO from 'date-fns/parseISO'
import { GetStaticProps } from 'next'
import Link from 'next/link'
import { ArticleCard, ArticlesSlider } from 'src/components/Article'
import { Layout } from 'src/components/Layout/Layout'
import { RecipeCard, RecipesSlider } from 'src/components/Recipes'
import { ShadcnButton } from 'src/components/ui/button'
import { Heading } from 'src/components/ui/Typography'
import { Articles } from 'src/operations/article/operations'
import { Recipes } from 'src/operations/recipe/operations'
import { initializeApollo } from 'src/utils/ApolloProvider'
import {
  ArticlesQuery,
  ArticlesQueryVariables,
  OrderByDirectionEnum,
  RecipesQuery,
  RecipesQueryVariables,
  useArticlesQuery,
  useRecipesQuery,
} from '../../../generated/graphql'
import { NextPageWithLayout } from '../../types/general'
import { Newsletter } from './components/Newsletter'
import { WelcomeFriendsSection } from './components/WelcomeFriendsSection'

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()
  try {
    const recipesQueryPromise = apolloClient
      .query<RecipesQuery, RecipesQueryVariables>({
        query: Recipes,
        variables: {
          pagination: {
            orderBy: 'publishedAt',
            orderByDirection: OrderByDirectionEnum.Desc,
            from: 0,
            length: 20,
          },
        },
      })
      .then((res) => res)
    const articlesQueryPromise = apolloClient
      .query<ArticlesQuery, ArticlesQueryVariables>({
        query: Articles,
        variables: {
          pagination: {
            orderBy: 'publishedAt',
            orderByDirection: OrderByDirectionEnum.Desc,
            from: 0,
            length: 20,
          },
        },
      })
      .then((res) => res)

    await Promise.all([recipesQueryPromise, articlesQueryPromise])

    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
      },
      revalidate: 180,
    }
  } catch {
    // ignore
  }

  return {
    props: {},
    revalidate: 1,
  }
}

export const Home: NextPageWithLayout = () => {
  const recipesQuery = useRecipesQuery({
    variables: {
      pagination: {
        orderBy: 'publishedAt',
        orderByDirection: OrderByDirectionEnum.Desc,
        from: 0,
        length: 20,
      },
    },
  })
  const articlesQuery = useArticlesQuery({
    variables: {
      pagination: {
        orderBy: 'publishedAt',
        orderByDirection: OrderByDirectionEnum.Desc,
        from: 0,
        length: 20,
      },
    },
  })

  const recipesSection = (
    <section>
      <div className="flex justify-between items-end mb-4 relative z-1">
        <Heading tag="h2" className="!leading-none">
          Recipes
        </Heading>
        <ShadcnButton variant="link" size="none" asChild>
          <Link href="/recipe" className="underline">
            Show all
          </Link>
        </ShadcnButton>
      </div>
      <div className="hidden sm:grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 relative">
        {recipesQuery.data?.recipes.recipes.map((recipe) => (
          <RecipeCard
            slug={recipe.slug}
            isBookmarked={recipe.isBookmarked}
            title={recipe.title}
            MetaDataJson={recipe.MetaDataJson}
            averageRating={recipe.averageRating}
            numOfRatings={recipe.numOfRatings}
            publishedAt={parseISO(recipe.publishedAt)}
            href={`/recipe/${recipe.slug}`}
            imageSrc={recipe.MainImage?.thumbnailSignedUrl ?? ''}
            shortDescription={recipe.description}
          />
        ))}
      </div>
      <div className="sm:hidden">
        <RecipesSlider recipes={recipesQuery.data?.recipes.recipes ?? []} />
      </div>
    </section>
  )

  return (
    <div className="space-y-20 pb-16">
      {recipesSection}

      <section className="relative py-4">
        <WelcomeFriendsSection className=" py-8 " />
        <div className="absolute z-[-1] top-0 h-full rounded-xl shadow-2xl bg-white -left-8 -right-8" />
      </section>

      {recipesSection}

      <section className="relative py-4">
        <Newsletter className="py-8" />
        <div className="absolute z-[-1] top-0 h-full rounded-xl shadow-2xl bg-white -left-8 -right-8" />
      </section>

      <div>
        <div className="flex justify-between items-end mb-4 relative z-[1]">
          <Heading tag="h2" className="!leading-none">
            Articles
          </Heading>
          <ShadcnButton variant="link" size="none" asChild>
            <Link href="/recipe" className="underline">
              Show all
            </Link>
          </ShadcnButton>
        </div>
        <div className="hidden sm:grid grid-cols-2 gap-4 relative">
          {articlesQuery.data?.articles.articles.map((article) => (
            <ArticleCard
              publishedAt={parseISO(article.publishedAt)}
              title={article.title}
              averageRating={article.averageRating}
              numOfRatings={article.numOfRatings}
              href={`/article/${article.slug}`}
              imageSrc={article.MainImage?.thumbnailSignedUrl ?? ''}
              shortDescription={article.description}
            />
          ))}
        </div>
        <div className="sm:hidden">
          <ArticlesSlider articles={articlesQuery.data?.articles.articles ?? []} />
        </div>
      </div>
    </div>
  )
}

Home.getLayout = (page) => <Layout>{page}</Layout>
