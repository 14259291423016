import * as React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import Image from 'next/image'
import { AuthCard } from 'src/components/Auth/AuthCard'
import { ShadcnButton } from 'src/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form'
import { ShadcnInput } from 'src/components/ui/input'
import { Heading, Text } from 'src/components/ui/Typography'
import * as z from 'zod'
import { useSubscribeToNewsletterMutation } from '../../../../generated/graphql'
import undraw_subscribe_vspl from './undraw_subscribe_vspl.svg'

export interface DescriptionProps {
  className?: string
}

export const Newsletter = ({ className }: DescriptionProps) => {
  const formSchema = z
    .object({
      email: z
        .string()
        .min(1, { message: 'This field has to be filled.' })
        .email({ message: 'This is not a valid email.' }),
      agreement: z.literal<boolean>(true),
    })
    .required({ email: true, agreement: true })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      agreement: true,
    },
  })

  const [subscribeToNewsletterMutation, subscribeToNewsletterMutationData] =
    useSubscribeToNewsletterMutation()

  function onSubmit(values: z.infer<typeof formSchema>) {
    void subscribeToNewsletterMutation({
      variables: {
        email: values.email,
      },
    })
  }

  return (
    <section className={classNames('space-y-4', className)}>
      <Heading tag="h2">Can&apos;t get enough of me?</Heading>
      <div className="flex flex-col items-start justify-center sm:flex-row sm:items-center">
        <Text
          className="hidden mr-2 rotate-180 cursor-vertical-text sm:inline"
          style={{ writingMode: 'vertical-rl' }}
        >
          No spam, just newsletter-worthy jam!
        </Text>
        <div className="relative w-full mb-8 sm:mb-0 sm:mr-16 h-72 flex-1">
          <Image className="w-full " src={undraw_subscribe_vspl} alt="" fill />
        </div>
        {subscribeToNewsletterMutationData.data?.subscribeToNewsletter ? (
          <div className="space-y-8 flex-1">
            <Heading tag="h4" weight="semi">
              Thanks for subscribing to my tasty newsletter?
            </Heading>
            <div className="space-y-4">
              <Text>To get even closer to me, have you though about creating an account?</Text>
              <AuthCard defaultType="signUp" defaultEmail={form.getValues().email}>
                <ShadcnButton type="button" className="">
                  <Text>Yes, sign up</Text>
                </ShadcnButton>
              </AuthCard>
            </div>
          </div>
        ) : (
          <div className="space-y-8 flex-1">
            <Heading tag="h4" weight="semi">
              Tired of newsletters that leave you as unsatisfied as an under-seasoned steak?
            </Heading>
            <div className="space-y-4">
              <Text>
                Well, it&apos;s time to spice up your inbox with my juicy cooking newsletter!
              </Text>
              <Text>
                Sign up now and pinky-promise that you will always leave me on read…just like that
                poor nice guy who never finds out how your day was 🥹
              </Text>
              <Text>
                Warning: This newsletter may cause uncontrollable cravings, a sudden urge to start
                cooking, and giggling. When in doubt, show this website to your mom and friends!
              </Text>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4 flex flex-col items-stretch"
                >
                  <div>
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <ShadcnInput placeholder="Email" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <ShadcnButton type="submit" className="">
                    <Text>Yes, sign me up</Text>
                  </ShadcnButton>
                </form>
              </Form>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
