import { gql } from '@apollo/client'
import { PUBLIC_USER_FRAGMENT } from '../shared-fragments'

export const Article = gql`
  query Article($slug: ID!) {
    article(slug: $slug) {
      slug
      createdAt
      updatedAt
      publishedAt
      title
      description
      content
      totalVotes
      Upvotes {
        ...PublicUserFragment
      }
      hasCurrentUserUpvoted
      hasCurrentUserDownvoted
      Author {
        ...PublicUserFragment
      }
      MainImage {
        key
        thumbnailSignedUrl(size: Large)
      }
    }
  }
  ${PUBLIC_USER_FRAGMENT}
`

export const Articles = gql`
  query Articles($pagination: PaginationInput!) {
    articles(pagination: $pagination) {
      articles {
        slug
        createdAt
        updatedAt
        publishedAt
        title
        description
        content
        averageRating
        numOfRatings
        totalVotes
        hasCurrentUserUpvoted
        hasCurrentUserDownvoted
        Author {
          ...PublicUserFragment
        }
        Upvotes {
          ...PublicUserFragment
        }
        MainImage {
          key
          thumbnailSignedUrl(size: Large)
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
  ${PUBLIC_USER_FRAGMENT}
`

export const CreateArticle = gql`
  mutation CreateArticle($inputData: ArticleInput!) {
    createArticle(inputData: $inputData) {
      slug
    }
  }
`

export const UpdateArticle = gql`
  mutation UpdateArticle($inputData: ArticleInput!, $slug: ID!) {
    updateArticle(inputData: $inputData, slug: $slug) {
      slug
    }
  }
`

export const DeleteArticle = gql`
  mutation DeletePost($slug: ID!) {
    deleteArticle(slug: $slug)
  }
`

export const VoteArticle = gql`
  mutation VoteArticle($inputData: VoteInput!, $slug: ID!) {
    voteArticle(inputData: $inputData, slug: $slug) {
      slug
    }
  }
`
