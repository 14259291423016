import * as React from 'react'
import { useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import classnames from 'classnames'
import { AuthCard } from 'src/components/Auth/AuthCard'
import { ShadcnButton } from 'src/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog'
import { useAuth } from 'src/utils/auth'
import { useSetBookmarkMutation } from '../../../generated/graphql'

export interface Props {
  slug: string
  isBookmarked: boolean
}

export const RecipeBookmarkButton = ({ slug, isBookmarked }: Props) => {
  const [openAuthType, setOpenAuthType] = useState<'prompt' | 'signUp' | 'signIn' | undefined>(
    undefined
  )
  const { isLoggedIn } = useAuth()
  const [setBookmarkMutation, setBookmarkMutationData] = useSetBookmarkMutation({
    variables: {
      inputData: {
        recipeSlug: slug,
        isBookmarked: !isBookmarked,
      },
    },
    refetchQueries: ['Recipes', 'Recipe'],
  })

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthType(undefined)
    }
  }

  const HeartIcon = isBookmarked ? AiFillHeart : AiOutlineHeart

  return (
    <>
      <ShadcnButton
        className={classnames('hover:bg-secondary', {
          'text-red-400': isBookmarked,
        })}
        variant="secondary"
        size="icon"
        onClick={() => {
          if (!isLoggedIn) {
            setOpenAuthType('prompt')
          } else if (!setBookmarkMutationData.loading) {
            setBookmarkMutation()
          }
        }}
      >
        <HeartIcon
          className={classnames('w-6 h-6 text-xl', {
            'animate-pulse repeat-infinite': setBookmarkMutationData.loading,
          })}
        />
      </ShadcnButton>
      <Dialog open={openAuthType === 'prompt'} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>You need to be logged in</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            In order to bookmark the recipe you need to be logged in
          </DialogDescription>

          <div className="flex space-x-2">
            <ShadcnButton variant="ghost" onClick={() => setOpenAuthType('signIn')}>
              Sign in
            </ShadcnButton>
            <ShadcnButton variant="ghost" onClick={() => setOpenAuthType('signUp')}>
              Sign up
            </ShadcnButton>
          </div>
        </DialogContent>
      </Dialog>
      <AuthCard
        forceOpen={openAuthType === 'prompt' ? undefined : openAuthType}
        onOpenChange={onOpenChange}
      />
    </>
  )
}
