import { gql } from '@apollo/client'

const RecipeFragment = gql`
  fragment RecipeFragment on Recipe {
    slug
    createdAt
    updatedAt
    publishedAt
    title
    description
    servings
    timesJson
    averageRating
    numOfRatings
    MetaDataJson
    isBookmarked
    RecipeDirectionGroups {
      id
      index
      label
      RecipeDirections {
        id
        index
        description
      }
    }
    RecipeIngredientGroups {
      id
      index
      label
      RecipeIngredients {
        id
        index
        label
        amount
        contains
        FoodIngredient {
          id
          label
          FoodIngredientMeasures {
            id
            gramWeight
            label
          }
          FoodIngredientNutrients {
            slug
            amount
            label
            unitName
          }
        }
        FoodIngredientMeasure {
          id
          gramWeight
          label
        }
      }
    }
    MainImage {
      key
      thumbnailSignedUrl(size: Large)
    }
    RecipeExtraTips {
      id
      index
      description
    }
    freeFrom
  }
`

export const Recipe = gql`
  query Recipe($slug: ID!) {
    recipe(slug: $slug) {
      ...RecipeFragment
      content
    }
  }
  ${RecipeFragment}
`

export const Recipes = gql`
  query Recipes($pagination: PaginationInput!) {
    recipes(pagination: $pagination) {
      recipes {
        ...RecipeFragment
      }
      pagination {
        from
        length
        total
      }
    }
  }
  ${RecipeFragment}
`

export const BookmarkedRecipes = gql`
  query BookmarkedRecipes($pagination: PaginationInput!) {
    bookmarkedRecipes(pagination: $pagination) {
      recipes {
        ...RecipeFragment
      }
      pagination {
        from
        length
        total
      }
    }
  }
  ${RecipeFragment}
`

export const CreateRecipe = gql`
  mutation CreateRecipe($inputData: RecipeInput!) {
    createRecipe(inputData: $inputData) {
      ...RecipeFragment
    }
  }
`

export const UpdateRecipe = gql`
  mutation UpdateRecipe($inputData: RecipeInput!, $slug: ID!) {
    updateRecipe(inputData: $inputData, slug: $slug) {
      ...RecipeFragment
    }
  }
`

export const DeleteRecipe = gql`
  mutation DeleteRecipe($slug: ID!) {
    deleteRecipe(slug: $slug)
  }
`
