import parseISO from 'date-fns/parseISO'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArticlesQuery } from '../../../generated/graphql'
import { ArticleCard } from './ArticleCard'

interface Props {
  articles: ArticlesQuery['articles']['articles']
}

export const ArticlesSlider = ({ articles }: Props) => (
  <Swiper
    className="!p-16 !-m-16 relative !z-[0]"
    spaceBetween={10}
    breakpoints={{
      0: {
        slidesPerView: 1.15,
        spaceBetween: 10,
      },
      400: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      900: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
    }}
    breakpointsBase="window"
    style={{ userSelect: 'none' }}
    pagination={{
      clickable: true,
      dynamicBullets: true,
      horizontalClass: '!bottom-8',
    }}
    modules={[Pagination, Navigation]}
  >
    {articles.map((article) => (
      <SwiperSlide key={article.slug} className="!h-auto">
        <ArticleCard
          className="hover:shadow-2xl h-full"
          averageRating={article.averageRating}
          numOfRatings={article.numOfRatings}
          publishedAt={parseISO(article.publishedAt)}
          title={article.title}
          shortDescription={article.description}
          imageSrc={article.MainImage?.thumbnailSignedUrl ?? ''}
          href={`/article/${article.slug}`}
        />
      </SwiperSlide>
    ))}
  </Swiper>
)
