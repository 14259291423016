import parseISO from 'date-fns/parseISO'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { RecipesQuery } from '../../../generated/graphql'
import { RecipeCard } from './RecipeCard'

interface Props {
  recipes: RecipesQuery['recipes']['recipes']
}

export const RecipesSlider = ({ recipes }: Props) => (
  <Swiper
    className="!p-16 !-m-16 relative !z-[0]"
    spaceBetween={10}
    breakpoints={{
      0: {
        slidesPerView: 1.25,
        spaceBetween: 10,
      },
      400: {
        slidesPerView: 1.75,
        spaceBetween: 10,
      },
      600: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      700: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      900: {
        slidesPerView: 3.5,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 4.15,
        spaceBetween: 24,
      },
    }}
    breakpointsBase="window"
    style={{ userSelect: 'none' }}
    pagination={{
      clickable: true,
      dynamicBullets: true,
      horizontalClass: '!bottom-8',
    }}
    modules={[Pagination, Navigation]}
  >
    {recipes.map((recipe) => (
      <SwiperSlide key={recipe.slug} className="!h-auto [&>*]:h-full">
        <RecipeCard
          slug={recipe.slug}
          isBookmarked={recipe.isBookmarked}
          title={recipe.title}
          MetaDataJson={recipe.MetaDataJson}
          averageRating={recipe.averageRating}
          numOfRatings={recipe.numOfRatings}
          publishedAt={parseISO(recipe.publishedAt)}
          href={`/recipe/${recipe.slug}`}
          imageSrc={recipe.MainImage?.thumbnailSignedUrl ?? ''}
          shortDescription={recipe.description}
        />
      </SwiperSlide>
    ))}
  </Swiper>
)
