import * as React from 'react'
import { AiOutlineComment } from 'react-icons/ai'
import classnames from 'classnames'
import { format } from 'date-fns'
import Image from 'next/image'
import Link from 'next/link'
import { Rating } from 'src/components/Rating'
import { Badge } from 'src/components/ui/badge'
import { Card } from 'src/components/ui/card'
import { Heading, Text } from 'src/components/ui/Typography'

export interface ArticleCardProps {
  href: string
  imageSrc: string
  title: string
  publishedAt: Date
  shortDescription: string
  className?: string
  averageRating: number
  numOfRatings: number
}

export const ArticleCard = ({
  imageSrc,
  publishedAt,
  averageRating,
  numOfRatings,
  title,
  href,
  shortDescription,
  className,
}: ArticleCardProps) => (
  <Card
    className={classnames(
      'relative border-0 shadow-none flex flex-col md:flex-row p-2 space-y-4 md:space-y-0 md:space-x-4 animate-in hover:shadow-2xl duration-300 transform hover:-translate-y-1 hover:bg-amber-100/10',
      className
    )}
  >
    <div className="flex-1">
      <div className="relative w-full pb-[70%] md:pb-[90%] z-[0]">
        <Image
          className="rounded-sm overflow-hidden"
          fill
          style={{ objectFit: 'cover' }}
          src={imageSrc}
          alt={title}
        />

        {publishedAt.getTime() > Date.now() ? (
          <Badge
            variant="destructive"
            className="rounded-sm px-1 font-normal absolute top-1 right-1"
          >
            {format(publishedAt, 'PP HH:mm')}
          </Badge>
        ) : (
          <div className="absolute top-1 left-1 rounded-md p-1 flex justify-between items-end bg-neutral-900/70">
            <Text size="sm" className="leading-none text-white">
              {format(publishedAt, 'PP')}
            </Text>
          </div>
        )}

        <div className="absolute bottom-1 left-1 right-1 rounded-md p-2 flex justify-between items-end bg-neutral-900/50">
          {/* <Text size="sm" className="leading-none"> */}
          {/*  {format(parseISO(createdAt), 'PP')} */}
          {/* </Text> */}
          {averageRating > 0 ? (
            <Rating
              size="Small"
              buttonSize="none"
              className="flex space-x-0.5"
              rating={Math.round(averageRating)}
              readOnly
            />
          ) : (
            <div />
          )}
          {numOfRatings > 0 ? (
            <div className="flex items-end space-x-1 text-white">
              <Text size="sm" className="leading-none">
                {numOfRatings}
              </Text>
              <AiOutlineComment size="1rem" />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="absolute bottom-0 left-0 w-full p-2 flex justify-between" />
      </div>
    </div>

    <div className="flex-[1.25] sm:flex-[1.75] md:flex-[2.25] flex flex-col space-y-2">
      <Link href={href} className="hover:underline z-[1]">
        <Heading size="h5" headingStyle="hand" className="">
          {title}
        </Heading>
      </Link>
      <Text className="pt-0 flex-1 font-mono text-sm">{shortDescription.slice(0, 100)}...</Text>
    </div>
    <Link href={href} className="absolute top-0 left-0 right-0 bottom-0" />
  </Card>
)
