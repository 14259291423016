import { gql } from '@apollo/client'

export const PUBLIC_USER_FRAGMENT = gql`
  fragment PublicUserFragment on PublicUser {
    id
    firstName
    lastName
    UserImage {
      key
      thumbnailSignedUrl(size: Medium)
    }
    title
    company
  }
`
