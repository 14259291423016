import { AiOutlineHeart } from 'react-icons/ai'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { Button } from 'src/components/ui/Button/Button'
import { Heading, Text } from 'src/components/ui/Typography'

export interface DescriptionProps {
  className?: string
}

const IntroText = `You've wandered onto a page that isn't just another ordinary and boring food blog. Why?
            Because I've never been a mainstream girl either. That’s what she cooked project will
            appeal to all people who love good food, see it as an art, have a sense of humour, and
            don't take life or vegans too seriously. If that description fits you, please be my
            friend and stay around. I would love to cook and write for you.`

export const WelcomeFriendsSection = ({ className }: DescriptionProps) => (
  <section className={classNames('space-y-4', className)}>
    <Heading tag="h2">Bon appétit-ies, mes amies</Heading>
    <div className="flex flex-col items-center justify-center sm:flex-row">
      <Text
        className="hidden mr-2 rotate-180 cursor-vertical-text sm:inline"
        style={{ writingMode: 'vertical-rl' }}
      >
        Laughter is the best spice
      </Text>
      <div className="relative w-full mb-8 bg-red-500 sm:mb-0 sm:mr-16 h-72">
        <Image className="w-full" src="/" alt="" fill />
      </div>
      <div className="space-y-8">
        <Heading tag="h3" weight="semi">
          I like my food like I like my jokes - well-seasoned! 🌶️
        </Heading>
        <div className="space-y-8">
          <Text>
            My secret ingredient? A dash of humour of a 14-year-old boy. Oh, and smoked paprika.
          </Text>
          <Text>{IntroText}</Text>
        </div>
        <Link href="/about" passHref>
          <Button
            variant="destructive"
            className="flex items-center justify-center mx-auto gap-x-2"
          >
            <Text>Learn More</Text>
            <AiOutlineHeart className="w-4 h-4 text-xl" />
          </Button>
        </Link>
      </div>
    </div>
  </section>
)
