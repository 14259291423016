import { Dispatch, SetStateAction, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import classNames from 'classnames'
import { buttonVariants, ShadcnButtonProps } from '../ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip'
import { Text } from '../ui/Typography'

const ratingTooltipText = ['Poor', 'Fair', 'Good', 'Excellent', 'WOW!']

export enum RatingSize {
  Small = 14,
  Medium = 21,
}

type RatingPropsBase = {
  rating: number
  size?: keyof typeof RatingSize
  className?: string
  buttonSize?: ShadcnButtonProps['size']
}

type RatingPropsConditional =
  | { readOnly?: false; onRatingChange?: Dispatch<SetStateAction<number>> }
  | { readOnly?: true; onRatingChange?: never }

export type RatingProps = RatingPropsBase & RatingPropsConditional

export const Rating = ({
  className,
  size = 'Medium',
  rating = 0,
  readOnly = true,
  onRatingChange,
  buttonSize = 'icon',
}: RatingProps) => {
  const [hover, setHover] = useState(0)

  return (
    <div className={className}>
      {[...Array(5)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TooltipProvider key={index}>
          <Tooltip>
            <TooltipTrigger
              className={classNames(
                buttonVariants({ variant: 'ghost', size: buttonSize }),
                'hover:text-yellow-500 group',
                {
                  'text-yellow-400': index + 1 <= (hover || rating),
                  'text-gray-300': !(index + 1 <= (hover || rating)),
                  'pointer-events-none': readOnly,
                }
              )}
              type="button"
              {...(!readOnly && {
                onClick: () => onRatingChange!(index + 1),
                onMouseEnter: () => setHover(index + 1),
                onMouseLeave: () => setHover(rating),
              })}
            >
              <AiFillStar
                size={RatingSize[size]}
                className="transition-all group-hover:scale-125"
              />
              <span className="sr-only">{index + 1} stars</span>
              <TooltipContent>
                <Text>{ratingTooltipText[index + 1]}</Text>
              </TooltipContent>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  )
}
