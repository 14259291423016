import { cn } from 'src/components/utils/utils'
import { ShadcnButton, ShadcnButtonProps } from '../button'

export interface ButtonProps extends ShadcnButtonProps {}

export const Button = ({ className, children, ...props }: ButtonProps) => (
  <ShadcnButton className={cn('hover:rounded-full transition-all', className)} {...props}>
    {children}
  </ShadcnButton>
)
